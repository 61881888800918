import { useMutation, useQuery } from '@tanstack/react-query';
import { PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { createCall } from '../../../api/adapters/appointment';
import {
    clearMessages,
    getMessages,
    unMatchUser,
} from '../../../api/adapters/chat';
import { reportUser } from '../../../api/adapters/report-user';
import FileIc from '../../../assets/images/file-ic.svg';
import DpPlaceholder from '../../../assets/images/no-dp.svg';
import SendIc from '../../../assets/images/send-icon.svg';
import { CommonContext } from '../../../context/CommonState';
import {
    handleApiError,
    handleQueryError,
} from '../../../utils/helpers/common.helpers';
import { getUserId } from '../../../utils/helpers/cookies.helpers';
import { getFullName } from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import LoadingButton from '../../components/mini-components/LoadingButton';
import ModalPopUp from '../ModalPopUp';
import { ChatLoader } from '../mini-components/ChatLoader';
import FancyBox from '../mini-components/FancyBox';
import MobileInput from '../mini-components/MobileInput';
import TextArea from '../mini-components/TextArea';
import ConversationOfDate from './ConversationOfDate';
import VoiceNote from './VoiceNote';
import VoiceRecorder from './VoiceRecorder';

function ChatBox(props) {
    const {
        selectedConversation,
        setConversations,
        socket,
        setSelectedConversation,
    } = props;

    const phoneUtil = PhoneNumberUtil.getInstance();
    const chatRefInput = useRef(null);
    const scrollChatBottom = useRef(null);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);

    const {
        user: userFromProp,
        setChaHistory,
        setMobileLayout,
        isMobile,
        setMobileLayoutUser,
        setInputWidth,
        report,
        setReportPopUp,
        reportUserData,
        setReportUserData,
        reportUserErrors,
        setReportUserErrors,
    } = useContext(CommonContext);

    const sendButtonRef = useRef(null);
    const fileInputRef = useRef();

    const [hasInputValue, setHasInputValue] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedAudio, setSelectedAudio] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [clearChat, setClearChatModal] = useState(false);
    const [unmatch, setUnmatchPopup] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    // const [report, setReportPopUp] = useState(false);
    const [messages, setMessages] = useState({});
    const [chatLoader, setChatLoader] = useState(false);

    // const [reportUserData, setReportUserData] = useState({
    //     country: {
    //         name: 'Spain',
    //         dialCode: '34',
    //         countryCode: 'es',
    //         format: '+.. ... ... ...',
    //     },
    //     reason: '',
    //     mobileNumber: '+34',
    // });
    // const [reportUserErrors, setReportUserErrors] = useState({
    //     reason: '',
    //     mobileNumber: '',
    // });

    const inputRef = useRef();

    // useEffect(() => {
    //     if (inputRef.current) {
    //         // inputRef.current.focus();
    //         setHasInputValue('');
    //     }
    // }, [messages]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [selectedConversation]);

    useEffect(() => {
        const onReceiveMessage = (data) => {
            if (data.oChatMessage.iConversation === selectedConversation) {
                if (
                    data.oChatMessage.bIsBlocked &&
                    data.oChatMessage.iSender === getUserId()
                )
                    notify('error', t('chat.sensitive_content'));
                setMessages((prevMessages) => {
                    const newMessages = { ...prevMessages };

                    if (!newMessages[data.dDate]) {
                        newMessages[data.dDate] = [data.oChatMessage];
                    } else {
                        if (
                            JSON.stringify(
                                newMessages[data.dDate][
                                    newMessages[data.dDate].length - 1
                                ]
                            ) !== JSON.stringify(data.oChatMessage)
                        ) {
                            newMessages[data.dDate].push(data.oChatMessage);
                        }
                    }

                    return { ...newMessages };
                });
            }
            setConversations((prevConversations) => {
                const newConversations = prevConversations;
                const indexToReplace = newConversations.findIndex(
                    (conversation) =>
                        conversation._id === data.oChatMessage.iConversation
                );

                const oUpdatedData = newConversations[indexToReplace];
                oUpdatedData.eMessageType = data.oChatMessage.eMessageType;
                oUpdatedData.bIsBlocked = data.oChatMessage.bIsBlocked;
                oUpdatedData.dLastMessageTime = data.oChatMessage.dCreatedAt;
                oUpdatedData.iLastConnectedUser = data.oChatMessage.iSender;
                oUpdatedData.sLastText =
                    data.oChatMessage.eMessageType === 'IMAGE' ||
                    data.oChatMessage.eMessageType === 'AUDIO'
                        ? ''
                        : data.oChatMessage.sContent;
                oUpdatedData.nUnreadMessages = data.nUnreadMessages;
                if (oUpdatedData.bIsCleared) oUpdatedData.bIsCleared = false;

                newConversations[indexToReplace] = oUpdatedData;
                newConversations.sort(compareByTimestamp);

                return [...newConversations];
            });
        };

        if (socket) {
            socket.on('message-received', onReceiveMessage);

            socket.emit('join-room', selectedConversation);
        }

        // setConversations((prevConversations) => {
        //     const newConversations = prevConversations;
        //     const indexToReplace = newConversations.findIndex(
        //         (conversation) => conversation._id === selectedConversation
        //     );

        //     const oUpdatedData = newConversations[indexToReplace];

        //     oUpdatedData.nUnreadMessages = 0;

        //     newConversations[indexToReplace] = oUpdatedData;

        //     return [...newConversations];
        // });

        setConversations((prevConversations) => {
            const newConversations = [...prevConversations]; // Create a copy of prevConversations

            const indexToReplace = newConversations.findIndex(
                (conversation) => conversation._id === selectedConversation
            );

            if (indexToReplace !== -1) {
                // Ensure indexToReplace is valid
                const oUpdatedData = { ...newConversations[indexToReplace] }; // Create a copy of the conversation object

                oUpdatedData.nUnreadMessages = 0;
                newConversations[indexToReplace] = oUpdatedData;

                return newConversations;
            } else {
                // Handle case where selectedConversation is not found
                console.error('Selected conversation not found');
                return prevConversations; // Return previous state unchanged
            }
        });

        return () => {
            if (socket) {
                socket.off('message-received', onReceiveMessage);
            }
        };
    }, [selectedConversation]);

    const {
        data: conversationData,
        isLoading: isConversationDataLoading,
        error: conversationsError,
        refetch: refetchConversation,
    } = useQuery({
        queryKey: [
            'messages',
            i18n.language?.split('-')[0],
            selectedConversation,
        ],
        queryFn: () =>
            getMessages(
                i18n.language?.split('-')[0],
                selectedConversation
            ).then((res) => res.data),
    });

    const { mutate: mutateReportUser, isLoading: isMutatingReportUser } =
        useMutation({
            mutationFn: (data) => reportUser(i18n.language.split('-')[0], data),
            onSuccess: (response) => {
                notify('success', response.message);

                setReportPopUp(false);
                setReportUserData({
                    country: {
                        name: 'Spain',
                        dialCode: '34',
                        countryCode: 'es',
                        format: '+.. ... ... ...',
                    },
                    reason: '',
                    mobileNumber: '+34',
                });
            },
            onError: (error) => handleApiError(error, t, navigate),
        });

    const { mutate: mutateClearMessages, isLoading: areMessagesClearing } =
        useMutation({
            mutationFn: () =>
                clearMessages(
                    i18n.language.split('-')[0],
                    selectedConversation
                ),
            onSuccess: (response) => {
                setConversations((prevConversations) => {
                    const newConversations = prevConversations;
                    const indexToReplace = newConversations.findIndex(
                        (conversation) =>
                            conversation._id === selectedConversation
                    );

                    const oUpdatedData = newConversations[indexToReplace];
                    oUpdatedData.sLastText = '';
                    oUpdatedData.bIsCleared = true;
                    oUpdatedData.aClearedUsers.push(getUserId());

                    newConversations[indexToReplace] = oUpdatedData;

                    return [...newConversations];
                });
                refetchConversation();
                setMessages({});
                notify('success', response.message);
                setClearChatModal(false);
            },
        });

    const { mutate: mutateUnmatchUser, isLoading: isUnMatchingUser } =
        useMutation({
            mutationFn: () =>
                unMatchUser(i18n.language.split('-')[0], selectedConversation),
            onSuccess: (response) => {
                notify('success', response.message);
                setConversations((prevConversations) =>
                    prevConversations.filter(
                        (conversation) =>
                            conversation._id !== selectedConversation
                    )
                );
                setSelectedConversation(null);
                setUnmatchPopup(false);
            },
            onError: (error) => handleApiError(error, t, navigate),
        });

    useEffect(() => {
        if (conversationsError) handleQueryError(conversationsError, navigate);
    }, [conversationsError]);

    const emitEvent = () => {
        socket.emit('send-message', {
            iRoom: selectedConversation,
            sMessage: hasInputValue,
            sImage: selectedImage,
            sImageType: selectedImage?.type,
            sAudio: selectedAudio,
            sAudioType: selectedAudio?.type,
        });

        setHasInputValue('');
        setSelectedImage(null);
        setPreviewImage(null);
        setSelectedAudio(null);
        fileInputRef.current.value = null;
    };

    useEffect(() => {
        if (conversationData?.aChatMessages)
            setMessages(conversationData.aChatMessages);
    }, [conversationData]);

    useEffect(() => {
        const parentContainer = scrollChatBottom.current;
        parentContainer?.scrollTo({
            top: parentContainer.scrollHeight,
        });
    }, [messages]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && (hasInputValue || selectedImage))
            sendButtonRef.current.click();
    };

    function compareByTimestamp(a, b) {
        const timestampA = new Date(a.dLastMessageTime).getTime();
        const timestampB = new Date(b.dLastMessageTime).getTime();
        return timestampB - timestampA;
    }

    const handleReportUserMobileChange = (
        _value,
        country,
        _e,
        formattedValue
    ) => {
        setReportUserData((prevReportUserData) => ({
            ...prevReportUserData,
            country,
            mobileNumber: formattedValue,
        }));
    };

    const handleReportUserReasonChange = (e) => {
        if (e.target.value.length > 350) return;

        setReportUserData((prevReportUserData) => ({
            ...prevReportUserData,
            reason: e.target.value,
        }));
    };

    const handleReportUserSubmit = () => {
        const existingErrors = { ...reportUserErrors };

        if (!reportUserData.reason.length)
            existingErrors.reason = t('report_user.error_enter_reason');
        else if (reportUserData.reason.length > 50)
            existingErrors.reason = t('report_user.error_reason_length');
        else existingErrors.reason = '';

        if (
            reportUserData.mobileNumber.replace(
                `+${reportUserData.country.dialCode}`,
                ''
            ).length
        ) {
            try {
                const number = phoneUtil.parseAndKeepRawInput(
                    reportUserData.mobileNumber,
                    reportUserData.country.countryCode
                );

                if (!phoneUtil.isValidNumber(number)) {
                    existingErrors.mobileNumber = t(
                        'report_user.error_invalid_mobile_number'
                    );
                } else {
                    existingErrors.mobileNumber = '';
                }
            } catch (error) {
                existingErrors.mobileNumber = t(
                    'report_user.error_invalid_mobile_number'
                );
            }
        } else {
            existingErrors.mobileNumber = '';
        }

        setReportUserErrors({ ...existingErrors });

        if (new Set(Object.values(existingErrors)).size !== 1) return;

        const data = {
            iUser: conversationData?.oConversation?.aParticipants[0]?._id,
            sMessage: reportUserData.reason,
        };

        if (
            reportUserData.mobileNumber.replace(
                `+${reportUserData.country.dialCode}`,
                ''
            ).length
        ) {
            data.sPhoneNumber = reportUserData.mobileNumber;
            data.sCountryCode = reportUserData.country.countryCode;
        }

        mutateReportUser(data);
    };

    useEffect(() => {
        if (chatRefInput.current) {
            setInputWidth(chatRefInput.current.offsetWidth);
        }
    });

    const handleSchedule = () => {
        // setModalShow(false); // Close the modal
        // console.log('in handle Schedule');
        navigate(
            `/schedule-call/${conversationData?.oConversation?.aParticipants[0]?._id}`,
            { state: { bFromCall: true } }
        );
    };

    // console.log({ conversationData });
    const [formErrors, setFormErrors] = useState({});

    const { mutate: mutateCreateCall, isLoading } = useMutation({
        mutationFn: (id) => createCall(i18n.language.split('-')[0], id),
        onSuccess: (response) => {
            notify('success', response.message);
            navigate('/call', { state: { oCallData: response.data } });
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });
    const currentTime = moment();

    const handleCreateCall = () => {
        const appointment = conversationData?.oAppointment;

        if (
            currentTime.isAfter(
                moment(appointment?.Slot?.dStartTime).subtract(2, 'minutes')
            ) &&
            currentTime.isBefore(moment(appointment?.Slot?.dEndTime))
        ) {
            mutateCreateCall(appointment?._id);
        }
    };

    const isDisabled =
        isLoading ||
        !conversationData?.oAppointment ||
        currentTime.isBefore(
            moment(conversationData?.oAppointment?.Slot?.dStartTime).subtract(
                2,
                'minutes'
            )
        ) ||
        currentTime.isAfter(
            moment(conversationData?.oAppointment?.Slot?.dEndTime)
        );

    useEffect(() => {
        if (conversationData?.oConversation?.aParticipants[0]?._id) {
            setPreviewImage(null);
            setSelectedImage(null);
            setSelectedAudio(null);
        }
    }, [conversationData?.oConversation?.aParticipants[0]?._id]);

    return (
        <>
            <div className='chat-box'>
                <div className='chat-header'>
                    <div className='user-dp-name'>
                        {isMobile && (
                            <button
                                className='simple-btn'
                                onClick={() => {
                                    setMobileLayoutUser(false);
                                    setMobileLayout(false);
                                }}
                            >
                                <img src='/images/back-arrow.svg' alt='' />
                            </button>
                        )}

                        <div className='dp'>
                            {isConversationDataLoading ? (
                                <img
                                    src='/images/profile-image-placeholder.svg'
                                    alt=''
                                />
                            ) : (
                                <FancyBox
                                    options={{
                                        Carousel: { infinite: false },
                                    }}
                                >
                                    <a
                                        data-fancybox='user-profile-image'
                                        data-caption={getFullName(
                                            conversationData?.oConversation
                                                .aParticipants[0].oName
                                        )}
                                        href={
                                            conversationData?.oConversation
                                                .aParticipants[0]
                                                .sProfilePicUrl || DpPlaceholder
                                        }
                                    >
                                        <img
                                            src={
                                                conversationData.oConversation
                                                    .aParticipants[0]
                                                    .sProfilePicUrl ||
                                                DpPlaceholder
                                            }
                                            alt=''
                                        />
                                    </a>
                                </FancyBox>
                            )}
                        </div>

                        {isConversationDataLoading ? null : (
                            <p>
                                {/* {getFullName(
                                    conversationData.oConversation
                                        .aParticipants[0].oName
                                )} */}
                                {
                                    conversationData?.oConversation
                                        .aParticipants[0].oName?.sFirstName
                                }
                            </p>
                        )}
                    </div>
                    <div className='header-action'>
                        {conversationData?.oConversation?.aParticipants[0]
                            ?.oName?.sFirstName !== 'Deleted' ? (
                            <button
                                onClick={(event) => {
                                    // () => handleCreateCall()
                                    if (
                                        (user.accountType === 'R' &&
                                            currentTime.isBefore(
                                                moment(
                                                    conversationData
                                                        ?.oAppointment?.Slot
                                                        ?.dStartTime
                                                ).subtract(2, 'minutes')
                                            )) ||
                                        currentTime.isAfter(
                                            moment(
                                                conversationData?.oAppointment
                                                    ?.Slot?.dEndTime
                                            )
                                        )
                                    ) {
                                        event.preventDefault();
                                        notify('error', t('call_btn_disabled'));
                                    } else {
                                        handleCreateCall();
                                    }
                                }}
                                // disabled={isDisabled}
                            >
                                {isDisabled ? (
                                    <img src='/images/call.svg' alt='' />
                                ) : (
                                    <img
                                        src='/images/call_blue_ic.svg'
                                        alt=''
                                    />
                                )}
                            </button>
                        ) : null}

                        <Dropdown className='chat-toggle'>
                            <Dropdown.Toggle
                                variant='unset'
                                id='dropdown-basic'
                            >
                                <img src='/images/more.svg' alt='' />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {user.accountType === 'S' &&
                                conversationData?.oConversation
                                    ?.aParticipants[0]?.oName?.sFirstName !==
                                    'Deleted' ? (
                                    <Dropdown.Item onClick={handleSchedule}>
                                        <span>{t('chat.schedule_call')}</span>
                                    </Dropdown.Item>
                                ) : null}

                                <Dropdown.Item
                                    onClick={() => setChaHistory(true)}
                                >
                                    <span>{t('chat.call_history')}</span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => setClearChatModal(true)}
                                >
                                    <span>{t('chat.clear_chat')}</span>
                                </Dropdown.Item>
                                {conversationData?.oConversation
                                    ?.aParticipants[0]?.oName?.sFirstName !==
                                'Deleted' ? (
                                    <>
                                        {' '}
                                        <Dropdown.Item
                                            onClick={() =>
                                                setUnmatchPopup(true)
                                            }
                                        >
                                            <span>{t('chat.unmatch')}</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => setReportPopUp(true)}
                                        >
                                            <span>{t('chat.report')}</span>
                                        </Dropdown.Item>
                                    </>
                                ) : null}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className='chat-container'>
                    <div className='msg-wrapper'>
                        {isConversationDataLoading ? (
                            <div className='chat-loader'>
                                <ChatLoader />
                            </div>
                        ) : Object.keys(messages).length === 0 ? (
                            <p className='no-msg text-primary'>
                                {t('chat.no_message_yet')}
                            </p>
                        ) : (
                            <>
                                <div
                                    ref={scrollChatBottom}
                                    className='massages'
                                >
                                    {Object.keys(messages).map((date) => {
                                        const messagesOfDate = messages[date];
                                        return (
                                            <ConversationOfDate
                                                messagesOfDate={messagesOfDate}
                                                date={date}
                                                key={date}
                                                userId={user?.id}
                                            />
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </div>

                    {isConversationDataLoading ||
                    conversationData?.oConversation?.aParticipants[0]?._id ? (
                        <div className='input-chat'>
                            {selectedAudio && (
                                <div className='audio-prev'>
                                    <VoiceNote voiceData={selectedAudio} />
                                    <button
                                        className='simple-btn'
                                        onClick={() => {
                                            setSelectedAudio('');
                                        }}
                                    >
                                        <img
                                            src='images/delete-red.svg'
                                            alt=''
                                        />{' '}
                                    </button>
                                </div>
                            )}
                            <div className='chat-input' ref={chatRefInput}>
                                {previewImage ? (
                                    <div className='preview'>
                                        <div className='preview-media'>
                                            <button
                                                className='close-icon'
                                                onClick={(e) => {
                                                    setSelectedImage(null);
                                                    setPreviewImage(null);
                                                    fileInputRef.current.value =
                                                        null;
                                                }}
                                            >
                                                <img
                                                    src='/images/remove.png'
                                                    alt=''
                                                />
                                            </button>
                                            <img src={previewImage} alt='' />
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}

                                <button>
                                    <input
                                        ref={fileInputRef}
                                        type='file'
                                        onChange={(e) => {
                                            if (e.target.files[0]) {
                                                setSelectedImage(
                                                    e.target.files[0]
                                                );
                                                const reader = new FileReader();

                                                reader.onload = (e) => {
                                                    setPreviewImage(
                                                        e.target.result
                                                    );
                                                };

                                                reader.readAsDataURL(
                                                    e.target.files[0]
                                                );
                                            }
                                        }}
                                    />
                                    <img src={FileIc} alt='' />
                                </button>
                                <input
                                    ref={inputRef}
                                    autoFocus
                                    value={hasInputValue}
                                    onKeyPress={handleKeyPress}
                                    onChange={(e) => {
                                        setHasInputValue(e.target.value);
                                    }}
                                    placeholder={t('chat.type_here')}
                                    type='text'
                                />
                                <VoiceRecorder
                                    setSelectedAudio={setSelectedAudio}
                                />
                            </div>
                            {(hasInputValue ||
                                selectedImage ||
                                selectedAudio) && (
                                <button
                                    ref={sendButtonRef}
                                    className='send-btn'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        emitEvent();
                                    }}
                                >
                                    <img src={SendIc} alt='' />
                                </button>
                            )}
                        </div>
                    ) : (
                        <div className='input-chat'>
                            <div className='chat-input deleted-account-message'>
                                {t('chat.cannot_send_message')}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* MODAL  */}
            {modalShow && (
                <ModalPopUp
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    modalcontent={
                        <>
                            <img src='/images/call-image.svg' alt='' />

                            <h3 className='popup-title'>
                                {t('chat.schedule_another')}
                            </h3>

                            <p className='sm'>
                                {t('chat.would_you', {
                                    name: getFullName(
                                        conversationData?.oConversation
                                            ?.aParticipants[0]?.oName
                                    ),
                                })}
                            </p>

                            <div className='popup-action'>
                                <button
                                    className='primary-btn'
                                    onClick={handleSchedule}
                                >
                                    {t('chat.yes')}
                                </button>
                                <button
                                    className='simple-btn'
                                    onClick={() => {
                                        setModalShow(false);
                                    }}
                                >
                                    {t('chat.no')}
                                </button>
                            </div>
                        </>
                    }
                />
            )}
            {/* CLEAR CHAT POPUP  */}
            <ModalPopUp
                show={clearChat}
                onHide={() => setClearChatModal(false)}
                modalcontent={
                    <>
                        <img src='/images/icons/warn-ic.svg' alt='' />

                        <h3 className='popup-title'>{t('chat.are_you')}</h3>

                        <p className='sm'>{t('chat.you_sure_clear_chat')}</p>

                        <div className='popup-action'>
                            <button
                                className='primary-btn'
                                onClick={() => setClearChatModal(false)}
                            >
                                {t('chat.no_dont')}
                            </button>
                            <button
                                className='simple-btn'
                                disabled={areMessagesClearing}
                                onClick={() => {
                                    mutateClearMessages();
                                }}
                            >
                                {areMessagesClearing ? (
                                    <LoadingButton />
                                ) : (
                                    t('chat.yes_delete')
                                )}
                            </button>
                        </div>
                    </>
                }
            />
            {/* unmatch POPUP  */}
            <ModalPopUp
                show={unmatch}
                onHide={() => setUnmatchPopup(false)}
                modalcontent={
                    <>
                        <img src='/images/icons/warn-ic.svg' alt='' />

                        <h3 className='popup-title'>{t('chat.are_you')}</h3>

                        <p className='sm'>{t('chat.unmatch_popup')}</p>
                        <div className='error-msg edit-profile-error justify-content-start'>
                            <img
                                style={{
                                    maxWidth: '16px',
                                    marginTop: '4px',
                                    lineHeight: '20px',
                                }}
                                src='/images/icons/error-ic.svg'
                                alt='error-ic'
                            />
                            <p
                                className='error small text-start'
                                style={{ fontSize: '14px' }}
                            >
                                {t('chat.you_will', {
                                    name: getFullName(
                                        conversationData?.oConversation
                                            ?.aParticipants[0]?.oName
                                    ),
                                })}
                            </p>
                        </div>
                        <div className='popup-action'>
                            <button
                                className='primary-btn'
                                onClick={() => setUnmatchPopup(false)}
                            >
                                {t('chat.no_dont')}
                            </button>
                            <button
                                className='simple-btn'
                                disabled={isUnMatchingUser}
                                onClick={mutateUnmatchUser}
                            >
                                {isUnMatchingUser ? (
                                    <LoadingButton />
                                ) : (
                                    t('chat.yes_unmatch')
                                )}
                            </button>
                        </div>
                    </>
                }
            />
            {/* REPORT POPUP  */}
            <ModalPopUp
                show={report}
                className='report-modal'
                onHide={() => {
                    setReportPopUp(false);
                    setReportUserData({
                        country: {
                            name: 'Spain',
                            dialCode: '34',
                            countryCode: 'es',
                            format: '+.. ... ... ...',
                        },
                        reason: '',
                        mobileNumber: '+34',
                    });
                    setReportUserErrors({
                        reason: '',
                        mobileNumber: '',
                    });
                }}
                modalcontent={
                    <div className='report-popup'>
                        <div className='report-dp'>
                            <img
                                src={
                                    conversationData?.oConversation
                                        ?.aParticipants[0]?.sProfilePicUrl ||
                                    DpPlaceholder
                                }
                                alt=''
                            />
                        </div>
                        <h3 className='popup-title'>
                            {`${conversationData?.oConversation?.aParticipants[0]?.oName?.sFirstName}`}
                            {/*  ${
                                conversationData?.oConversation
                                    ?.aParticipants[0]?.oName?.sMiddleName || ''
                            } ${
                                conversationData?.oConversation
                                    ?.aParticipants[0]?.oName?.sLastName || ''
                            } */}
                        </h3>
                        <h4 className='blue-text'>{t('chat.report_user')}</h4>
                        <p className='sm'>
                            {t('chat.please_share')} <br />
                            {/* <strong>La denuncia es anónima</strong> */}
                        </p>

                        <TextArea
                            placeholder={`${t('chat.report_reason')}...`}
                            value={reportUserData.reason}
                            handleOnChange={handleReportUserReasonChange}
                            charactersCount={reportUserData.reason.length}
                            errorMessage={reportUserErrors.reason}
                            maxCharacter={350}
                        />

                        <div className='text-start'>
                            <div className='country-input mt-2'>
                                <p>{t('mobile_label')}</p>
                                <MobileInput
                                    country={reportUserData.country.countryCode}
                                    value={reportUserData.mobileNumber}
                                    handleMobileChange={
                                        handleReportUserMobileChange
                                    }
                                    errorMessage={reportUserErrors.mobileNumber}
                                />
                            </div>
                        </div>
                        <div className='mt-2'>
                            <Form.Check // prettier-ignore
                                type={'checkbox'}
                                id={`login-checkbox`}
                                label={
                                    <>
                                        {t('i_accept')}{' '}
                                        <Link
                                            className='simple-btn blue-text'
                                            // onClick={() =>
                                            //     setShowModal(true)
                                            // }
                                            to={'/legal-notice'}
                                            target='_blank'
                                        >
                                            {' '}
                                            {t('footer_links.terms_conditions')}
                                            ,{' '}
                                        </Link>
                                        <Link
                                            className='simple-btn blue-text'
                                            target='_blank'
                                            to={'/privacy-policy'}
                                        >
                                            {t('footer_links.privacy_policy')}{' '}
                                        </Link>{' '}
                                        {t('and')}{' '}
                                        <Link
                                            to={'/cookies'}
                                            className='simple-btn blue-text'
                                            target='_blank'
                                        >
                                            {t('footer_links.cookies_title')}
                                        </Link>
                                    </>
                                }
                                className='check-box'
                            />
                        </div>
                        <div className='popup-action'>
                            <button
                                className='primary-btn'
                                onClick={handleReportUserSubmit}
                                disabled={isMutatingReportUser}
                            >
                                {isMutatingReportUser ? (
                                    <LoadingButton color={true} />
                                ) : (
                                    t('chat.submit')
                                )}
                            </button>
                        </div>
                    </div>
                }
            />
        </>
    );
}

export default ChatBox;
