import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Categories from '../../components/common-component/Categories';
import TitleHeader from '../../components/common-component/TitleHeader';
import UserDetails from './UserDetails';

function ScheduleReject(props) {
    const { t } = useTranslation();
    const {
        dialoguerData,
        interestHeading = false,
        handleReject,
        handleSchedule,
    } = props;

    return (
        <div className='schedule-call content-wrapper border-wrapper h-100'>
            <TitleHeader
                blueTitle={true}
                title='Dialoguer'
                btn_title={t('home.schedule_call')}
                btnClick={handleSchedule}
                rejectBtn={t('home.reject')}
                handleRejectClick={() => handleReject(dialoguerData._id)}
                back_btn={false}
            />

            <div className='schedule-call-data'>
                <div className='row'>
                    <UserDetails dialoguerData={dialoguerData} />

                    <div className='col-md-8'>
                        {interestHeading && (
                            <p className='lg interest-heading'>Interests</p>
                        )}

                        {dialoguerData.aMatchedInterests?.length ? (
                            <div className='matched'>
                                <Categories
                                    interestItems={dialoguerData.aMatchedInterests.reduce(
                                        (acc, cur) => {
                                            const categoryInterestItems =
                                                cur.interestCategory.aInterestItems.map(
                                                    (item) => ({
                                                        sTitle: item.sTitle,
                                                        sIconUrl: item.sIconUrl,
                                                    })
                                                );
                                            acc.push(...categoryInterestItems);
                                            return acc;
                                        },
                                        []
                                    )}
                                    isSelectable={false}
                                    title={t('home.matched_interests')}
                                />
                            </div>
                        ) : null}

                        <div className='unmatched'>
                            {dialoguerData.aUnmatchedInterests.map(
                                (element) => {
                                    const interestItems =
                                        element.interestCategory.aInterestItems.map(
                                            (interestItem) => ({
                                                sTitle: interestItem.sTitle,
                                                sIconUrl: interestItem.sIconUrl,
                                            })
                                        );

                                    return (
                                        <Categories
                                            key={element.interestCategory._id}
                                            interestItems={interestItems}
                                            title={
                                                element.interestCategory.sTitle
                                            }
                                        />
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ScheduleReject.propTypes = {
    handleReject: PropTypes.func,
    handleSchedule: PropTypes.func,
    isActionButtonDisabled: PropTypes.bool,
    dialoguerData: PropTypes.shape({
        _id: PropTypes.string,
        oName: PropTypes.shape({
            sFirstName: PropTypes.string,
            // sMiddleName: PropTypes.string,
            // sLastName: PropTypes.string,
        }),
        sBio: PropTypes.string,
        sProfilePicUrl: PropTypes.string,
        aMatchedInterests: PropTypes.arrayOf(
            PropTypes.shape({
                interestCategory: PropTypes.shape({
                    _id: PropTypes.string,
                    sTitle: PropTypes.string,
                    aInterestItems: PropTypes.arrayOf(
                        PropTypes.shape({
                            _id: PropTypes.string,
                            sTitle: PropTypes.string,
                            sIconUrl: PropTypes.string,
                            interestCategory: PropTypes.shape({
                                _id: PropTypes.string,
                                sTitle: PropTypes.string,
                            }),
                        })
                    ),
                }),
            })
        ),
        aUnmatchedInterests: PropTypes.arrayOf(
            PropTypes.shape({
                interestCategory: PropTypes.shape({
                    _id: PropTypes.string,
                    sTitle: PropTypes.string,
                    aInterestItems: PropTypes.arrayOf(
                        PropTypes.shape({
                            _id: PropTypes.string,
                            sTitle: PropTypes.string,
                            sIconUrl: PropTypes.string,
                            interestCategory: PropTypes.shape({
                                _id: PropTypes.string,
                                sTitle: PropTypes.string,
                            }),
                        })
                    ),
                }),
            })
        ),
        oCity: PropTypes.shape({
            sTitle: PropTypes.string,
        }),
        oCountry: PropTypes.shape({
            sTitle: PropTypes.string,
        }),
    }),
};

export default ScheduleReject;
