import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function CallToaster(props) {
    const { t } = useTranslation();
    const { conversationText, setIsCheckToaster, isCheckToaster } = props;

    const [showChatBooster, setShowChatBooster] = useState(false);
    const [isCheckBooster, setIsCheckBooster] = useState(false);
    // const [conversationIndex, setConversationIndex] = useState(0);

    useEffect(() => {
        if (conversationText && !showChatBooster) {
            setShowChatBooster(true);

            const timer = setTimeout(() => {
                setShowChatBooster(false);
            }, 30 * 1000); // 30 seconds
            return () => clearTimeout(timer); // Clear the timer when the component unmounts
        } else {
            setShowChatBooster(false);
        }
    }, [isCheckToaster, conversationText]);

    // useEffect(() => {
    //     let timeoutIds = [];

    //     // Show the first message after 2 minutes (120,000ms)
    //     timeoutIds.push(
    //         setTimeout(() => {
    //             if (conversationText && conversationIndex === 0) {
    //                 setShowChatBooster(true);
    //                 setConversationIndex(1); // Move to next conversation index
    //                 const timer = setTimeout(() => {
    //                     setShowChatBooster(false);
    //                 }, 30 * 1000); // Display for 30 seconds
    //                 timeoutIds.push(timer);
    //             }
    //         }, 2 * 60 * 1000) // 2 minutes
    //     );

    //     // Show the second message after 10 minutes (600,000ms)
    //     timeoutIds.push(
    //         setTimeout(() => {
    //             if (conversationText && conversationIndex === 1) {
    //                 setShowChatBooster(true);
    //                 setConversationIndex(2); // Move to next conversation index
    //                 const timer = setTimeout(() => {
    //                     setShowChatBooster(false);
    //                 }, 30 * 1000); // Display for 30 seconds
    //                 timeoutIds.push(timer);
    //             }
    //         }, 10 * 60 * 1000) // 10 minutes
    //     );

    //     // Show the third message after 20 minutes (1,200,000ms)
    //     timeoutIds.push(
    //         setTimeout(() => {
    //             if (conversationText && conversationIndex === 2) {
    //                 setShowChatBooster(true);
    //                 const timer = setTimeout(() => {
    //                     setShowChatBooster(false);
    //                 }, 30 * 1000); // Display for 30 seconds
    //                 timeoutIds.push(timer);
    //             }
    //         }, 20 * 60 * 1000) // 20 minutes
    //     );

    //     return () => {
    //         timeoutIds.forEach(clearTimeout); // Cleanup all timeouts
    //     };
    // }, [conversationText, conversationIndex, isCheckToaster]);

    return (
        <div className='call-toaster'>
            {isCheckToaster && showChatBooster && conversationText && (
                <div className='call-chat-booster'>
                    <>
                        <p>{t('suggestion')}</p>
                        <p className='sm'>{conversationText}</p>
                    </>
                </div>
            )}

            {isCheckBooster && (
                <div className='call-chat-booster'>
                    <>
                        <label style={{ color: 'white' }}>
                            {t('want_suggestion')}
                        </label>
                        <div className='toaster-checkbox'>
                            <Form>
                                <Form.Check // prettier-ignore
                                    type={'checkbox'}
                                    onChange={() => {
                                        // setIsCheckToaster(true);
                                        isCheckToaster
                                            ? setIsCheckToaster(false)
                                            : setIsCheckToaster(true);
                                        setIsCheckBooster(false);
                                    }}
                                    id={`default-checkbox`}
                                    label={t('yes_or_no')}
                                    checked={isCheckToaster}
                                />
                            </Form>
                        </div>
                    </>
                </div>
            )}

            <div className='icon text-end'>
                <button
                    className='simple-btn'
                    onClick={() => {
                        // setIsCheckToaster(true);
                        isCheckBooster
                            ? setIsCheckBooster(false)
                            : setIsCheckBooster(true);
                        setShowChatBooster(false);
                    }}
                >
                    {isCheckToaster && (
                        <img src='/images/icon-call.svg' alt='' />
                    )}
                    {!isCheckToaster && (
                        <img src='/images/isCheck_ic.svg' alt='' />
                    )}
                </button>
            </div>
        </div>
    );
}

export default CallToaster;
